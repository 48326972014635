
/**
 * @name: 数据统计-商品销售明细
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 数据统计-商品销售明细
 * @update: 2023-12-07 16:39
 */
import { Vue, Component } from "vue-property-decorator"
import {productSaleExportApi, queryProductSaleByPageApi} from "@/apis/statistics/saleDetail"
import { ISaleDetailParam, ISaleDetail } from "@/apis/statistics/saleDetail/types"
import type { ICrudOption } from "@/types/m-ui-crud"
import {deepCopy, exportFile} from "@/utils/common";

@Component({})
export default class statisticsSaleDetail extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: ISaleDetail[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: ISaleDetailParam = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "商品",
        prop: "productKeywords",
        search: true,
        hide: true,
        placeholder: "商品ID/商品名称"
      },
      {
        label: "商品ID",
        prop: "productId",
        align: "left",
        width: 180
      },
      {
        label: "商品名称",
        prop: "productName",
        align: "center",
        overHidden: true
      },
      {
        label: "商品分类",
        prop: "productSortId",
        search: true,
        hide: true,
        type: "select",
        dicUrl: "/api/card/productSort/dropDown",
        dicFormatter: (res: any) => ({list: res.data, label: 'sortName', value: 'productSortId'}),
      },
      {
        label: "商品分类",
        prop: "productSortName",
        align: "center",
        overHidden: true
      },
      {
        label: "状态",
        prop: "productStatus",
        search: true,
        hide: true,
        type: "select",
        dicData: [
          {
            label: "上架",
            value: 1
          },
          {
            label: "下架",
            value: 2
          }
        ]
      },
      {
        label: "销售数量",
        prop: "payQty",
        align: "center",
        slot: true,
        width: 100
      },
      {
        label: "商品售价(元)",
        prop: "price",
        align: "center",
        width: 120
      },
      {
        label: "商品金额",
        prop: "productPrice",
        align: "center",
        width: 120
      },
      {
        label: "用户名",
        prop: "userName",
        align: "center",
        overHidden: true
      },
      {
        label: "手机号",
        prop: "userPhone",
        align: "center",
      },
      {
        label: "订单编号",
        prop: "orderSn",
        align: "center",
        search: true,
        width: 180
      },
      {
        label: "消费时间",
        prop: "payTime",
        search: true,
        width: 150,
        align: "center",
        type: "daterange"
      },
      {
        label: "用户",
        prop: "userKeywords",
        search: true,
        hide: true,
        placeholder: "用户名/手机号"
      }
    ]
  }

  payQtyTotal = 0
  productPriceTotal = 0

  getList () {
    this.tableLoading = true

    const query: ISaleDetailParam = deepCopy(this.queryParam)

    if (query.payTime && query.payTime.length) {
      query.payTimeStart = query.payTime[0]
      query.payTimeEnd = query.payTime[1]
    } else {
      query.payTimeStart = ''
      query.payTimeEnd = ''
    }
    delete query.payTime

    queryProductSaleByPageApi(query).then((e) => {

      this.tableData = e.commonPage.list
      this.tableTotal = e.commonPage.total

      this.payQtyTotal = e.payQtyTotal || 0
      this.productPriceTotal = e.productPriceTotal || 0

      this.tableLoading = false
    })
  }

  exportExcel () {
    const query: ISaleDetailParam = deepCopy(this.queryParam)

    if (query.payTime && query.payTime.length) {
      query.payTimeStart = query.payTime[0]
      query.payTimeEnd = query.payTime[1]
    } else {
      query.payTimeStart = ''
      query.payTimeEnd = ''
    }
    delete query.payTime

    productSaleExportApi(query).then(e => {
      exportFile(e, "商品销售明细.xlsx")
    })
  }

  created () {
    this.getList()
  }
}
